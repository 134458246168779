import React, { useState, useRef, useEffect } from 'react';
import { intlShape } from '../../util/reactIntl';
import { FieldSelect, FieldRadioButton, FieldTextInput, FieldDateInput } from '..';
import * as validators from '../../util/validators';
import {
  WISE_DATE_TYPE,
  WISE_RADIO_TYPE,
  WISE_SELECT_TYPE,
  WISE_TEXT_TYPE,
} from './WiseFormConfig';
import moment from 'moment';
import { any, array, bool, func } from 'prop-types';
import { FormSpy } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import css from './WiseConnectAccountForm.module.css';
import { supportedCurrencies as defaultSupportedCurrencies } from '../../config/configWise';

const identity = v => v;

const getChangedRequiredField = ({ currentValue, newValue, refreshRequiredField }) => {
  return refreshRequiredField.reduce((currentChangedRequiredFields, currentRequiredFieldKey) => {
    if (
      isEqual(get(currentValue, currentRequiredFieldKey), get(newValue, currentRequiredFieldKey))
    ) {
      return currentChangedRequiredFields;
    }
    return [...currentChangedRequiredFields, currentRequiredFieldKey];
  }, []);
};

const YearSelection = () => {
  const currentYear = moment().year();
  const yearOptions = Array.from({ length: 111 }, (_, index) => currentYear - index)
    .map(year => (
      <option key={year} value={year}>
        {year}
      </option>));
  return yearOptions;
};

const DynamicSection = ({
  wiseRequirements,
  onRequestWiseRequirement,
  values,
  intl,
}) => {
  if (!Array.isArray(wiseRequirements)) {
    return null;
  }

  const refreshRequiredField = useRef({});
  const formData = useRef(values);

  const paymentTypeLabel = intl.formatMessage({
    id: 'WiseConnectAccountForm.paymentTypeLabel',
  });

  const paymentTypeRequiredMessage = intl.formatMessage({
    id: 'WiseConnectAccountForm.paymentTypeRequiredMessage',
  });
  const paymentTypeRequired = validators.required(paymentTypeRequiredMessage);

  const chosenRequirement = values?.type
    ? wiseRequirements.find(requirement => requirement.type === values.type)
    : null;

  return (
    <>
      <FormSpy
        onChange={formState => {
          const changedValues = formState.values;
          if (Object.keys(changedValues).length < 1) {
            return;
          }
          //Store the form data for when we refresh the page to update new requirement data, user's input would not disappear
          const changedRequiredFields = getChangedRequiredField({
            currentValue: formData.current || {},
            refreshRequiredField: Object.keys(refreshRequiredField.current),
            newValue: changedValues,
          });
          formData.current = changedValues;
          if (changedRequiredFields.length < 1) {
            return;
          }
          return onRequestWiseRequirement(formData.current);
        }}
      />
      <FieldSelect
        id="type"
        name="type"
        className={css.selectCurrency}
        label={paymentTypeLabel}
        validate={paymentTypeRequired}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'WiseConnectAccountForm.selectPaymentType' })}
        </option>
        {wiseRequirements.map(requirement => (
          <option key={requirement.type} value={requirement.type}>
            {intl.formatMessage({
              id: `WiseConnectAccountForm.selectPaymentType.${requirement.type}`,
              defaultMessage: requirement.title,
            })}
          </option>
        ))}
      </FieldSelect>
      {chosenRequirement ? (
        <div className={css.sectionContainer}>
          {chosenRequirement.fields.map(field => {
            const element = field.group[0];
            const {
              displayFormat,
              example,
              key,
              maxLength,
              minLength,
              name,
              refreshRequirementsOnChange,
              required,
              type: rawType,
              validationAsync,
              validationRegexp,
              valuesAllowed,
            } = element;

            if (refreshRequirementsOnChange) {
              refreshRequiredField.current[key] = true;
            }

            //TODO: remove this code if production JPY is normal, replace it with a catch instead
            //For dev JPY select does not have values
            const type =
              rawType === WISE_SELECT_TYPE && !valuesAllowed && required
                ? WISE_TEXT_TYPE
                : rawType;

            const label = intl.formatMessage({
              id: `WiseConnectAccountForm.${key}.label`,
              defaultMessage: name,
            });
            const placeholder = intl.formatMessage({
              id: `WiseConnectAccountForm.${key}.placeholder`,
              defaultMessage: example,
            });
            const requiredMessage = intl.formatMessage({ id: `WiseConnectAccountForm.${key}.required` });
            const requiredValidator = required
              ? validators.required(requiredMessage)
              : undefined;

            const dynamicValidator =
              maxLength || minLength || validationAsync || validationRegexp
                ? validators.validateDynamicField({
                    validationRegexp,
                    valuesAllowed,
                    maxLength,
                    minLength,
                })(intl.formatMessage({ id: `WiseConnectAccountForm.${key}.validatorError` }))
                : undefined;

            //TODO: change this to JSON for easy access
            switch (type) {
              case WISE_SELECT_TYPE: {
                return (
                  <FieldSelect
                    key={key}
                    id={key}
                    name={key}
                    className={css.dynamicSelect}
                    label={label}
                    validate={requiredValidator}
                  >
                    <option disabled value="">
                      {placeholder}
                    </option>
                    {valuesAllowed.map(({ key: optionKey, name: label }, i) => (
                      <option key={`${optionKey}-jh${i}`} value={optionKey}>
                        {intl.formatMessage({
                          id: `WiseConnectAccountForm.${key}.${optionKey}`,
                          defaultMessage: label,
                        })}
                      </option>
                    ))}
                  </FieldSelect>
                );
              }
              case WISE_TEXT_TYPE: {
                return (
                  <FieldTextInput
                    id={key}
                    type="text"
                    key={key}
                    className={css.dynamicText}
                    name={key}
                    placeholder={placeholder}
                    label={label}
                    validate={
                      required && dynamicValidator
                        ? validators.composeValidators(requiredValidator, dynamicValidator)
                        : requiredValidator || dynamicValidator
                    }
                  />
                );
              }
              case WISE_RADIO_TYPE: {
                return (
                  <div className={css.radioButtonRow}>
                    {valuesAllowed.map(({ key: keyValueAllowed, name }, index) => {
                      return (
                        <FieldRadioButton
                          key={`${key}-${index}`}
                          id={keyValueAllowed}
                          name={key}
                          label={intl.formatMessage({
                            id: `WiseConnectAccountForm.${key}.${keyValueAllowed}`,
                            defaultMessage: name,
                          })}
                          value={keyValueAllowed}
                          showAsRequired={required}
                        />
                      );
                    })}
                  </div>
                );
              }
              case WISE_DATE_TYPE: {
                const dateValidator = required
                  ? composeValidators(requiredValidator, validators.bookingDateRequired(requiredMessage))
                  : undefined;
                //TODO: Replace this date picker with a more versatile
                return (
                  <div className={css.dateSection}>
                    <FieldDateInput
                      key={key}
                      name={key}
                      useMobileMargins={false}
                      id={key}
                      label={label}
                      placeholderText={moment().format(displayFormat)}
                      format={identity}
                      validate={dateValidator}
                      isOutsideRange={() => false}
                      renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                        <div className={css.monthElement}>
                          <div>
                            <select
                              value={month.month()}
                              onChange={e => onMonthSelect(month, e.target.value)}
                            >
                              {moment.months().map((label, value) => (
                                <option value={value}>{label}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <select
                              value={month.year()}
                              onChange={e => onYearSelect(month, e.target.value)}
                            >
                              <YearSelection />
                            </select>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                );
              }
              default: {
                console.warn(`unknown type ${type}, wise has updated their API again :()`);
                return null;
              }
            }
          })}
        </div>
      ) : null}
    </>
  );
};

const WiseDynamicForm = props => {
  const {
    wiseRequirements,
    onRequestWiseRequirement,
    values,
    supportedCurrencies,
    disabled,
    intl,
  } = props;

  const [currentCurrency, setCurrentCurrency] = useState(null);

  useEffect(() => {
    if (!!values && !!values.currency && values.currency !== currentCurrency) {
      onRequestWiseRequirement({
        currency: values.currency,
      });
      setCurrentCurrency(values.currency);
    }
  }, [wiseRequirements, values.currency]);

  const currencyLabel = intl.formatMessage({ id: 'WiseConnectAccountForm.currencyLabel' });

  const currencyRequiredMessage = intl.formatMessage({
    id: 'WiseConnectAccountForm.currencyRequiredMessage',
  });
  const currencyRequired = validators.required(currencyRequiredMessage);

  return (
    <div className={css.sectionContainer}>
      <FieldSelect
        id="currency"
        name="currency"
        disabled={disabled}
        className={css.selectCurrency}
        autoComplete="currency"
        label={currencyLabel}
        validate={currencyRequired}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'WiseConnectAccountForm.currencyPlaceholder' })}
        </option>
        {supportedCurrencies.map(c => (
          <option key={c} value={c}>
            {intl.formatMessage({
              id: `WiseConnectAccountForm.currency.${c}`,
              defaultMessage: c,
            })}
          </option>
        ))}
      </FieldSelect>
      <DynamicSection
        onRequestWiseRequirement={onRequestWiseRequirement}
        wiseRequirements={wiseRequirements}
        values={values}
        intl={intl}
      />
    </div>
  );
};

WiseDynamicForm.defaultProps = {
  supportedCurrencies: defaultSupportedCurrencies,
};

WiseDynamicForm.propTypes = {
  supportedCurrencies: array.isRequired,
  wiseRequirements: any,
  onRequestWiseRequirement: func.isRequired,
  disabled: bool,
  intl: intlShape,
};

export default WiseDynamicForm;
