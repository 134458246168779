import React from 'react';
import { withViewport } from '../../util/uiHelpers';
import ListingPageCarousel from './ListingPageCarousel';
import ListingPageCoverPhoto from './ListingPageCoverPhoto';
import { number, shape } from 'prop-types';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const ListingPageComponent = ({ viewport, ...props }) => {
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  return isMobileLayout ? (
    <ListingPageCoverPhoto {...props} />
  ) : (
    <ListingPageCarousel {...props} />
  );
};

ListingPageComponent.propTypes = {
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const ListingPage = withViewport(ListingPageComponent);
export default ListingPage;
