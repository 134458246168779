import React from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import {
  Button,
  Form,
} from '..';
import WiseDynamicForm from './WiseDynamicForm';

import css from './WiseConnectAccountForm.module.css';

const WiseConnectAccountFormComponent = props => {
  const { onSubmit, ...restOfProps } = props;
  return (
    <FinalForm
      {...restOfProps}
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          className,
          children,
          disabled,
          handleSubmit,
          saveWiseDetailsInProgress,
          fetchWiseRequirementsInProgress,
          intl,
          invalid,
          pristine,
          ready,
          submitButtonText,
          values,
          stripeConnected,
          onRequestWiseRequirement,
          wiseRequirements,
        } = fieldRenderProps;

        const accountDataLoaded = true;
        const submitInProgress = saveWiseDetailsInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const classes = classNames(css.root, className, {
          [css.disabled]: disabled,
        });

        // Don't show the submit button while fetching the Stripe account data
        const submitButtonMaybe =
          !stripeConnected || accountDataLoaded ? (
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={ready}
            >
              {submitButtonText || (
                <FormattedMessage id="WiseConnectAccountForm.submitButtonText" />
              )}
            </Button>
          ) : null;

        // If the Stripe publishable key is not set up, don't show the form
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {accountDataLoaded ? (
              <WiseDynamicForm
                wiseRequirements={wiseRequirements}
                onRequestWiseRequirement={onRequestWiseRequirement}
                intl={intl}
                values={values}
              />
            ) : (
              <div className={css.savedInformation}>
                <FormattedMessage id="WiseConnectAccountForm.loadingStripeAccountData" />
              </div>
            )}

            {children}
            {fetchWiseRequirementsInProgress && (
              <div className={css.fetchRequirements}>
                <FormattedMessage id="WiseConnectAccountForm.loadingRequirements" />
              </div>
            )}
            {submitButtonMaybe}
          </Form>
        );
      }}
    />
  );
};

WiseConnectAccountFormComponent.defaultProps = {
  className: null,
  currentUser: null,
  disabled: false,
  ready: false,
  submitButtonText: null,
  saveWiseDetailsInProgress: false,
  fetchWiseRequirementsInProgress: false,
};

WiseConnectAccountFormComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  disabled: bool,
  ready: bool,
  submitButtonText: string,
  saveWiseDetailsInProgress: bool,
  fetchWiseRequirementsInProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const WiseConnectAccountForm = compose(injectIntl)(WiseConnectAccountFormComponent);

export default WiseConnectAccountForm;
